import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    rowsList: [],
    open: false,
    hasError: false,
    lang: "fr",
    rows: [],
    apparatus: {},
    validationErrors: [],
    selectedFile: null,
    isFilePicked: false,
    previewImg: "",
    openModalDeleteApparatus: false,
    idApparatusToDelete: null,
    name: '',
    enabled: null,
    reference: '',
    productRange: null,
    context: null,
    imageUrl: null,
}

export const ApparatusSlice = createSlice({
    name: 'apparatus',
    initialState,
    reducers: {
        setRowsList: (state, action) => {
            state.rowsList = action.payload
        },
        setOpen: (state, action) => {
            state.open = action.payload
        },
        setHasError: (state, action) => {
            state.hasError = action.payload
        },
        setLang: (state, action) => {
            state.lang = action.payload
        },
        setRows: (state, action) => {
            state.rows = action.payload
        },
        setApparatus: (state, action) => {
            state.apparatus = action.payload
        },
        setValidationErrors: (state, action) => {
            state.validationErrors = action.payload
        },
        setSelectedFile: (state, action) => {
            state.selectedFile = action.payload
        },
        setIsFilePicked: (state, action) => {
            state.isFilePicked = action.payload
        },
        setPreviewImg: (state, action) => {
            state.previewImg = action.payload
        },
        setOpenModalDeleteApparatus: (state, action) => {
            state.openModalDeleteApparatus = action.payload
        },
        setIdApparatusToDelete: (state, action) => {
            state.idApparatusToDelete = action.payload
        },
        setName: (state, action) => {
            state.name = action.payload
        },
        setEnabled: (state, action) => {
            state.enabled = action.payload
        },
        setReference: (state, action) => {
            state.reference = action.payload
        },
        setProductRange: (state, action) => {
            state.productRange = action.payload
        },
        setContext: (state, action) => {
            state.context = action.payload
        },
        resetApparatusData: (state, action) => {
            state.apparatus = {}
            state.selectedFile = null
            state.isFilePicked = false
            state.previewImg = ""
            state.openModalDeleteApparatus = false
            state.idApparatusToDelete = null
            state.name = ''
            state.enabled = null
            state.reference = ''
            state.productRange = null
        },
        setImageUrl: (state, action) => {
            state.imageUrl = action.payload
        }
    }
})

export const {
    setRowsList,
    setOpen,
    setHasError,
    setLang,
    setRows,
    setApparatus,
    setValidationErrors,
    setSelectedFile,
    setIsFilePicked,
    setPreviewImg,
    setOpenModalDeleteApparatus,
    setIdApparatusToDelete,
    setName,
    setEnabled,
    setReference,
    setProductRange,
    setContext,
    resetApparatusData,
    setImageUrl
} = ApparatusSlice.actions
export default ApparatusSlice.reducer
