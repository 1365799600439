import { deleteApparatus } from "../api/ApparatusAPI"
import { addMessageAlert, setOpenAlert } from "../redux/App/AppSlice"
import { store } from "../redux/Store"
import { isNotEmpty } from "./AppServices"
import messagesconfig from "../config/messagesconfig.json"

export const deleteApparatusService = async (id_apparatus, id_site) => {
    if(isNotEmpty(id_apparatus) && !isNaN(id_apparatus) && isNotEmpty(id_site) && !isNaN(id_site)){
        let result = await deleteApparatus(id_apparatus, id_site)

        if(result.status === 'error' || result.data.error === true){
            store.dispatch(setOpenAlert(true))
            store.dispatch(addMessageAlert({message: messagesconfig.ApparatusServices.ERROR_DELETE_APPARATUS, type: "error"}))
        }else{
            store.dispatch(setOpenAlert(true))
            store.dispatch(addMessageAlert({message: messagesconfig.ApparatusServices.SUCCESS_DELETE_APPARATUS, type: "success"}))
        }
        return
    }
    store.dispatch(addMessageAlert({message: messagesconfig.ApparatusServices.ERROR_DELETE_APPARATUS, type: "error"}))
}