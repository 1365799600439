import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { DataGrid, frFR, GridColDef } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getApparatuses } from "../../api/ApparatusAPI";
import {useNavigate} from "react-router-dom";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PageTitle from '../../components/PageTitle';
import { setRowsList } from '../../redux/Apparatus/ApparatusSlice';
import { store } from '../../redux/Store';
import TooltipGrid from '../../components/shared/TooltipGrid';

export default function Apparatus(props) {
    
    let navigate = useNavigate();

    const rowsList = useSelector((state)=>state.apparatus.rowsList)

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', renderHeader: (params) => (
            <strong>{"ID"}</strong>
        )
        },
        {
            field: 'picture',
            flex: 1,
            headerName: 'Image',
            renderHeader: (params) => (
                <TooltipGrid value={"Image"} jsx={<strong>{"Image"}</strong>} />
            ),
            renderCell: (params) => (
                <img alt="Illustration" width='18%' src={params.value} />
            ),
        },
        {field: 'productRange',headerName: 'Gamme',flex: 1,
        renderHeader: (params) => (
            <TooltipGrid value={"Gamme"} jsx={<strong>{"Gamme"}</strong>} />
        )
        },
        {field: 'reference',headerName: 'Référence',flex: 1,
        renderHeader: (params) => (
            <TooltipGrid value={"Référence"} jsx={<strong>{"Référence"}</strong>} />
        )
        },
        {field: 'name',headerName: 'Nom', flex: 1,
        renderHeader: (params) => (
            <TooltipGrid value={"Nom"} jsx={<strong>{"Nom"}</strong>} />
        )
        },
        {
            field: 'enabled',
            flex: 1,
            headerName: 'Actif',
            renderHeader: (params) => (
                <TooltipGrid value={"Actif"} jsx={<strong>{"Actif"}</strong>} />
            ),
            renderCell: (params) => {
                if(params.value)
                    return <CheckCircleOutlinedIcon color="success" />
                else
                    return <CancelOutlinedIcon color="error" />
            },
        },
    ];

    useEffect(() => {    
        async function createData() {
            const rows = await getApparatuses() 
            store.dispatch(setRowsList(rows));
        }
        createData();
    }, []);

    const openRow = event => {
        navigate("/apparatus/"+ event.currentTarget.dataset.id)
    }

    return (
        <Box>
            <PageTitle title="Liste des agrès" />
            <Box display="flex" justifyContent="right" mb={2}>
                <Button onClick={() => {navigate("/apparatus");}} variant="contained" startIcon={<AddCircleIcon />}>Créer un agrès</Button>
            </Box>
            <Box sx={{ height: 500, width: '100%', '& .disabledRow': {bgcolor: '#fff'} }}>
              <DataGrid localeText={{...frFR.components.MuiDataGrid.defaultProps.localeText}} sx={{ '& .MuiDataGrid-row': { cursor: 'pointer' }, }} rows={rowsList} columns={columns} loading={rowsList.length === 0} autoHeight componentsProps={{ row: { onClick: openRow },}} disableSelectionOnClick getRowClassName={(params) => params.row.enabled ? '' : 'disabledRow'} />
            </Box>
        </Box>
        );
}
