import * as React from 'react';
import {useState, useEffect} from 'react'
import { useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListIcon from '@mui/icons-material/List';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {Outlet, Link as RouterLink} from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import AlertMessage from './AlertMessage';
import Stack from '@mui/material/Stack';
import Banner from './Banner'

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
}),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
}),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
},
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  backgroundColor: '#575756',
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
}),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
  }),
}),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
  }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
  }),
}),
  );

export default function Layout() {
  const [open, setOpen] = React.useState(true);
  let navigate = useNavigate();

  const messagesAlert = useSelector((state)=>state.app.messagesAlert)

  const [preprodEnv, setPreprodEnv] = useState(true)

  useEffect(()=>{
    if(window.location.href.indexOf('actifunbo.proludic.com') === -1) {
      setPreprodEnv(false)
    }
  }, [])

  const handleDrawerOpen = () => {
    setOpen(true);
};

const handleDrawerClose = () => {
    setOpen(false);
};

return (
    <Box>
      <Box sx={{ display: 'flex', position: 'relative', zIndex: 1 }}>
          <AppBar position="fixed" color="primary" open={open}>
              <Toolbar>
                  <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" sx={{display: 'inline-block', marginRight: 5, ...(open && { display: 'none' }) }} >
                      <MenuIcon />
                  </IconButton>
                  <Box component="div" sx={{ flexGrow: 1}}/>
                  <Link component={RouterLink} to="/logout" color="white" sx={{'&:hover, &:focus': {color: '#f89522'}}}underline="none">Se déconnecter <PowerSettingsNewIcon sx={{verticalAlign: 'middle', color: '#f89522'}} /></Link>
              </Toolbar>

          </AppBar>
          <Drawer variant="permanent" open={open}>
              <DrawerHeader>
                  <Link component={RouterLink} to="/">
                      <img src="/logo-actifun-blanc.svg" height="40" alt="actifun"/>
                  </Link>
                  <IconButton onClick={handleDrawerClose}><ChevronLeftIcon /></IconButton>
              </DrawerHeader>
              <Divider />
              <List>
                  <ListItem sx={{'&:hover, &:focus': {color: '#f89522', '& svg': { color: '#f89522'}},}} key="Dashboard" onClick={() => {navigate("/");}}>
                      <ListItemIcon>
                          <DashboardIcon />
                      </ListItemIcon>
                      <ListItemText primary="Dashboard" />
                  </ListItem>
                  <ListItem sx={{'&:hover, &:focus': {color: '#f89522', '& svg': { color: '#f89522'}},}} key="Liste-sites" onClick={() => {navigate("/sites");}}>
                      <ListItemIcon>
                          <LocationOnIcon />
                      </ListItemIcon>
                      <ListItemText primary="Liste des sites" />
                  </ListItem>
                  <ListItem sx={{'&:hover, &:focus': {color: '#f89522', '& svg': { color: '#f89522'}},}} key="Liste-apparatus" onClick={() => {navigate("/apparatuses");}}>
                      <ListItemIcon>
                          <ListIcon />
                      </ListItemIcon>
                      <ListItemText primary="Liste des agrès" />
                  </ListItem>
                  <ListItem sx={{'&:hover, &:focus': {color: '#f89522', '& svg': { color: '#f89522'}},}} key="Agrès" onClick={() => {navigate("/apparatus");}}>
                      <ListItemIcon>
                          <AddCircleRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Ajouter un agrès" />
                  </ListItem>
              </List>
              <Box sx={{pb:5, mb: 5, height: '73%'}} />
              <Box sx={{pb:5, textAlign: 'center'}} >
                <img src="/logo-proludic.png" width="50%" alt="actifun"/>
              </Box>
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, p: 3, pt: 5, m: 5 }}>
              <Outlet />
          </Box>
      </Box>
      <Box>
        <div style={{position: "fixed", bottom: "10px", left: "10px", zIndex: 2}}>
              <Stack spacing={1}>
                  {messagesAlert.map((ma, i) => {
                          return <AlertMessage key={i} ma={ma} />
                      })}  
              </Stack>
          </div>
      </Box>
    </Box>
    );
}